import {Role} from "~/constants";
import IncomesScreen from "~/screens/incomes";
import CreateIncomeScreen from "~/screens/incomes/create";
import {PrivateRoute} from "~/Application";
import {Switch} from "react-router-dom";
import UpdateIncomeScreen from "~/screens/incomes/update";

const IncomesRouter = () => (
  <Switch>
    <PrivateRoute exact allows={[Role.admin, Role.developer]} path="/incomes">
      <IncomesScreen />
    </PrivateRoute>
    <PrivateRoute exact allows={[Role.admin, Role.developer]} path="/incomes/create">
      <CreateIncomeScreen />
    </PrivateRoute>
    <PrivateRoute exact allows={[Role.admin, Role.developer]} path="/incomes/:id/edit">
      <UpdateIncomeScreen />
    </PrivateRoute>
  </Switch>
)

export default IncomesRouter