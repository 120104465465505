import Repository from '../contracts/Repository'
import RepositoryContext from '../contracts/RepositoryContext';
import { AxiosResponse } from 'axios'
import { Account } from '~/api/models'

export interface ICreateAccountBody {
    number: string | number
    bank_id?: number | string,
    type: string,
    titular_id?: number | string,
    capital?: string | number
    phone_number?: string
}

export interface IAccountEndpointsContext extends Repository<Account, ICreateAccountBody, ICreateAccountBody> {}

const endpoints = ({ axios, mapQueryString }: RepositoryContext): IAccountEndpointsContext => ({
    index: <T>(query: {}) => axios.get(`/api/accounts${mapQueryString(query)}`).then(({ data }: AxiosResponse<T>) => data),
    show: (id: number, query: {}) => axios.get(`/api/accounts/${id}${mapQueryString(query)}`).then(({ data }: AxiosResponse<Account>) => data),
    create: (body: ICreateAccountBody) => axios.post(`/api/accounts`, body).then(({ data }: AxiosResponse<Account>) => new Account(data)),
    update: (id: number, body: ICreateAccountBody) => axios.patch(`/api/accounts/${id}`, body).then(({ data }: AxiosResponse<Account>) => new Account(data)),
    remove: (id: number) => axios.delete(`/api/accounts/${id}`)
})


export default endpoints