import {useEffect, useState} from 'react';
import {useBreadcrumbs} from '~/context/breadcrumb';
import {Table, Select, DeleteModal, Dropdown, DropdownItem, Button, TitlePortal, Portal} from '~/components/ui';
import {useRepository} from '~/components/hooks'
import {Pagination, Account} from '~/api/models';
import CreateAccountModal from './modals/create'
import AssignTitularModal from './modals/assign'

const AccountsScreen = () => {

  const { setBreadcrumb } = useBreadcrumbs()
  const [query] = useState({ limit: 10, page: 1, with: 'titular,bank', sort_by: 'created_at,desc' })
  const [modals, setModals] = useState({ create: false, remove: false, assign: false })
  const [selected, setSelected] = useState<Account | undefined>(undefined)
  const [pagination, setPagination] = useState<Pagination<Account>>({ data: [] })
  const [loadings, setLoading] = useState({ index: false, remove: false })
  const { fetchAccounts, removeTitular } = useRepository(({ accounts }) => ({
    fetchAccounts: accounts.index,
    removeTitular: accounts.remove
  }))

  useEffect(() => {
    fetch()
    setBreadcrumb([
      { title: 'Listado de cuentas', to: '/accounts', loading: false },
    ])
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const select = (item: Account, cb: () => void) => {
    setSelected(item)
    cb()
  }

  const fetch = (filters = {}) => {

    setLoading(prev => ({...prev, index: !prev.index}))

    fetchAccounts<Pagination<Account>>({...query, ...filters})
      .then(pagination => new Pagination<Account>(pagination, $0 => new Account($0)))
      .then(pagination => setPagination(pagination))
      .finally(() => setLoading(prev => ({...prev, index: !prev.index})))
  }

  const deleteAccount = () => {
    setLoading(prev => ({...prev, remove: !prev.remove}))
    removeTitular(selected!.id)
      .then(() => {
        fetch({ page: 1 })
        setModals(prev => ({...prev, remove: false}))
      })
      .finally(() => setLoading(prev => ({...prev, remove: !prev.remove})))
  }

  return (
    <>
      <TitlePortal>
        Listado de cuentas bancarias
      </TitlePortal>
      <Portal target="#main_action">
        <Button variant="primary" onClick={() => setModals(prev => ({...prev, create: true}))}>
          Crear cuenta
        </Button>
      </Portal>
      <div className="w-full p-2">
        <Table
          columnFilter
          onSearch={search => fetch({ search, page: 1 })}
          loading={loadings.index}
          source={pagination.data}
          pagination={pagination}
          onPageChange={page => fetch({ page })}
          onRefresh={() => fetch({ page: 1 })}
          columns={[
            {title: 'Número', path: 'number'},
            {title: 'Tipo', path: 'type', filterField: () =>  (
              <Select onChange={type => fetch({ type })}>
                <option value="">-- TODAS --</option>
                <option value="Ahorro">Ahorro</option>
                <option value="Corriente">Corriente</option>
              </Select>
            )},
            {title: 'Banco', path: 'bank.name'},
            {title: 'Titular', path: 'titular.name', td: ({ item }) => <span className={!item.titular?.name ? 'text-gray-400' : ''}>{item.titular?.name || '-- Sin titular asociado -- '}</span>},
            {title: 'Telefono', path: 'phone_number', td: ({ item }) => <span className={!item.phone_number ? 'text-gray-400' : ''}>{item.phone_number || '-- No posee número de teléfono -- '}</span>},
            {title: 'Ámbito', path: 'titular.scope', filterField: () => (
                <Select loading={loadings.index} onChange={scope => fetch({ scope })}>
                  <option value="">-- TODOS --</option>
                  {/* <option value="Empresa">Empresa</option> */}
                  <option value="Empleado">Empleado</option>
                  <option value="Externo">Externo</option>
                </Select>
            )},
            {title: 'Acciones', path: 'id', td: ({ item }) => (
                <Dropdown right>
                  <DropdownItem to={`/accounts/${item.id}`}>Ver detalles</DropdownItem>
                  <DropdownItem to={`/accounts/${item.id}/paysheets`}>Ver órdenes de nómina</DropdownItem>
                  <DropdownItem to={`/accounts/${item.id}/payments`}>Ver órdenes de pago</DropdownItem>
                  {!item.titular && (
                    <>
                      <hr/>
                      <DropdownItem onClick={() => select(item, () => setModals(prev => ({...prev, assign: true})))}>Asignar titular</DropdownItem>
                    </>
                  )}
                  <hr/>
                  <DropdownItem onClick={() => select(item, () => setModals(prev => ({...prev, remove: true})))}>
                    Eliminar
                  </DropdownItem>
                </Dropdown>
              ), columnFilterable: false},
            ]} />
        </div>

        <DeleteModal
          show={modals.remove}
          title="Eliminar titular"
          heading="Un momento!! esta seguro de que desea eliminar el titular: "
          subheading={pagination.data.find($0 => $0.id === selected?.id)?.number ?? 0}
          loading={loadings.remove}
          onConfirm={deleteAccount}
          onCancel={() => setModals(prev => ({...prev, remove: !prev.remove}))} />

        <CreateAccountModal
          show={modals.create}
          onCreated={() => {
            fetch({ page: 1 })
            setModals(prev => ({...prev, create: false}))
          }}
          onCancel={() => setModals(prev => ({...prev, create: false}))}/>

        <AssignTitularModal
          show={modals.assign}
          account={selected}
          onAssigned={() => {
            fetch({ page: 1 })
            setModals(prev => ({...prev, create: false}))
          }}
          onCancel={() => setModals(prev => ({...prev, assign: false}))} />
    </>
  )
}

export default AccountsScreen
