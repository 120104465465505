import {useState, useEffect} from 'react'
import {Input, Select, Button} from '~/components/ui'
import {useRepository} from '~/components/hooks'
import {pickMap} from '~/utils/Object'
import {Account, Titular, Bank} from '~/api/models'
import {ICreateAccountBody} from '~/api/repositories/accounts'

const UpdateAccount = ({ account, onUpdate }: { account?: Account, onUpdate: (_: Account) => void }) => {

    const {update, fetchBanks, fetchTitulars} = useRepository(({ accounts, banks, titulars }) => ({
        update: accounts.update,
        fetchBanks: banks.index,
        fetchTitulars: titulars.index
    }))

    const [state, setState] = useState<ICreateAccountBody>({ number: 0, bank_id: undefined, type: '', titular_id: undefined, phone_number: '' })
    const [banks, setBanks] = useState<Bank[]>([])
    const [titulars, setTitulars] = useState<Titular[]>([])

    useEffect(() => {
        Promise.all([
            fetchBanks<Bank[]>({ limit: 0 }),
            fetchTitulars<Titular[]>({ limit: 0 }),
        ])
        .then(([$0, $1]) => {
            setBanks($0)
            setTitulars($1)
        })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (account !== undefined) {
            setState(pickMap(account, ({ number, bank_id, type, titular_id, phone_number }) => ({ number, bank_id, type, titular_id, phone_number })))
        }
    }, [account]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className=" flex flex-wrap -mx-2">
            <div className="w-full mb-4 px-2">
                <h2 className="text-2xl dark:text-gray-200">Actualizar datos</h2>
            </div>
            <div className="w-full xl:w-1/2 px-2">
                <Input
                    counter
                    id="number"
                    label="Número"
                    value={state.number}
                    onChange={number => setState(prev => ({...prev, number}))} />
            </div>
            <div className="w-full xl:w-1/2 px-2 mt-4 xl:mt-0">
                <Select
                    id="bank_id"
                    label="Banco"
                    value={state.bank_id?.toString()}
                    onChange={bank_id => setState(prev => ({...prev, bank_id }))}>
                    {banks.map($0 => <option key={$0.id} value={$0.id}>{$0.name}</option>)}
                </Select>
            </div>
            <div className="w-full xl:w-1/3 px-2 mt-4">
                <Select
                    id="type"
                    label="Tipo"
                    value={state.type}
                    onChange={type => setState(prev => ({...prev, type: type as string }))}>
                    <option value="Ahorro">Ahorro</option>
                    <option value="Corriente">Corriente</option>
                </Select>
            </div>
            <div className="w-full xl:w-1/3 px-2 mt-4">
                <Select
                    id="titular_id"
                    label="Titular"
                    value={state.titular_id?.toString()}
                    onChange={titular_id => setState(prev => ({...prev, titular_id }))}>
                    <option value="">-- Titular --</option>
                    {titulars.map($0 => <option key={$0.id} value={$0.id}>{$0.name}</option>)}
                </Select>
            </div>

             <div className="w-full xl:w-1/3 px-2 mt-4">
                <Input
                    id="phone_number"
                    label="Número de telefono"
                    value={state.phone_number}
                    onChange={phone_number => setState(prev => ({...prev, phone_number}))} />
            </div>

            <div className="flex w-full px-2 justify-end mt-4">
                {account !== undefined && (
                    <Button onClick={() => update(account.id, state).then($0 => onUpdate({
                        ...account,
                        ...pickMap($0, ({ type, number, titular_id, bank_id, phone_number }) => ({ type, number, titular_id, bank_id, phone_number }))
                    }))}>
                        Actualizar
                    </Button>
                )}
            </div>
        </div>
    )
}

export default UpdateAccount
