import Account from './Account'
import Titular from './Titular'
import Bank from './Bank'

class Payment {
    id: number
    order: number
    number: string
    purchase_id?: number
    titular_id?: number
    enterprise_id?: number
    account_id?: number
    transaction: string
    concept: string
    amount: string | number
    observation: string
    realized: string
    authorized: string
    issued_at: string

    bank?: Bank
    titular?: Titular
    account?: Account
    enterprise?: Account

    constructor(map: Payment) {
        this.id = map.id
        this.order = map.order
        this.number = map.number
        this.purchase_id = map.purchase_id
        this.titular_id = map.titular_id
        this.enterprise_id = map.enterprise_id
        this.account_id = map.account_id
        this.transaction = map.transaction
        this.concept = map.concept
        this.amount = map.amount
        this.observation = map.observation
        this.realized = map.realized
        this.authorized = map.authorized
        this.issued_at = map.issued_at

        this.bank = map.bank
        this.titular = map.titular
        this.account = map.account
        this.enterprise = map.enterprise
    }
}

export default Payment