import Bank from './Bank'
import Titular from './Titular'
class Account {

    id: number
    bank_id: number
    titular_id?: number
    number: number | string
    phone_number: string
    type: string
    capital?: number

    bank?: Bank
    titular?: Titular

    payments_count?: number
    paysheets_count?: number

    constructor(map: Account) {
        this.id = map.id
        this.bank_id = map.bank_id
        this.titular_id = map.titular_id
        this.number = map.number
        this.phone_number = map.phone_number
        this.type = map.type
        this.capital = map.capital

        this.bank = map.bank
        this.titular = map.titular

        this.payments_count = map.payments_count
        this.paysheets_count = map.paysheets_count
    }
}

export default Account