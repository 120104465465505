import { useState, FormEvent, useEffect } from 'react';
import { Modal, Input, Select } from '~/components/ui'
import { useRepository, useError } from '~/components/hooks'
import { Account, Titular, Bank } from '~/api/models';
import { ICreateAccountBody } from '~/api/repositories/accounts'
import { Link } from 'react-router-dom'

interface ICreateAccountModalProps {
    show: boolean,
    onCreated: (_: Account) => void
    onCancel: () => void
    onError?: () => void
    onFinish?: () => void
}

const CreateAccountModal = ({ show, onCancel, onCreated, onError, onFinish }: ICreateAccountModalProps) => {

    const [loading, setLoading] = useState(false)
    const [banks, setBanks] = useState<Bank[]>([])
    const [titulars, setTitulars] = useState<Titular[]>([])
    const [state, setState] = useState<ICreateAccountBody>({ number: 0, bank_id: undefined, type: '', titular_id: undefined, phone_number: '' })
    const { error, setErrors } = useError()
    const { create, fetchBanks, fetchTitulars } = useRepository(({ accounts, banks, titulars }) => ({
        create: accounts.create,
        fetchBanks: banks.index,
        fetchTitulars: titulars.index
    }))

    const handleCreate = (event?: FormEvent) => {
        event?.preventDefault()
        setLoading(prev => !prev)
        create({...state, capital: 0})
            .then(onCreated)
            .catch(setErrors())
            /* .catch(err => {
                setErrors()
                onError?.()
            }) */
            .finally(() => {
                setLoading(prev => !prev)
                onFinish?.()
            })
    }

    useEffect(() => {
        Promise.all([
            fetchBanks<Bank[]>({ limit: 0 }),
            fetchTitulars<Titular[]>({ limit: 0 }),
        ])
        .then(([$0, $1]) => {
            setBanks($0)
            setTitulars($1)
        })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Modal
            show={show}
            title="Crear cuenta"
            confirmText={loading ? 'Procesando...' : 'Guardar'}
            onConfirm={handleCreate}
            onCancel={onCancel}>
            <form className="flex flex-col" onSubmit={handleCreate}>
                <Input
                    counter
                    className="w-full"
                    id="number"
                    label="Número"
                    value={state.number}
                    error={error('number')}
                    onChange={number => setState(prev => ({...prev, number}))} />

                <Select
                    className="w-full mt-4"
                    id="bank_id"
                    label={<div className="w-full flex justify-between">Banco <Link to="/banks" className="text-blue-400 font-normal">¿Crear banco?</Link></div>}
                    value={state.bank_id?.toString()}
                    error={error('bank_id')}
                    onChange={bank_id => setState(prev => ({...prev, bank_id}))}>
                    <option value="">-- Banco --</option>
                    {banks.map($0 => <option key={$0.id} value={$0.id}>{$0.name}</option>)}
                </Select>

                <Select
                    className="w-full mt-4"
                    id="type"
                    label="Tipo"
                    value={state.type}
                    error={error('type')}
                    onChange={type => setState(prev => ({...prev, type: type as string }))}>
                    <option value="">-- Tipo --</option>
                    <option value="Ahorro">Ahorro</option>
                    <option value="Corriente">Corriente</option>
                </Select>

                <Input
                    //counter
                    className="w-full"
                    id="phone_number"
                    label="Número de Telefono"
                    value={state.phone_number}
                    error={error('phone_number')}
                    onChange={phone_number => setState(prev => ({...prev, phone_number}))} />

                <Select
                    className="w-full mt-4"
                    id="titualar_id"
                    label="Titular (Opcional)"
                    value={state.titular_id?.toString()}
                    error={error('titular_id')}
                    onChange={titular_id => setState(prev => ({...prev, titular_id }))}>
                    <option value="">-- Titular --</option>
                    {titulars.map($0 => <option key={$0.id} value={$0.id}>{$0.name}</option>)}
                </Select>
            </form>
        </Modal>
    )
}

export default CreateAccountModal
