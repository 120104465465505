import {useEffect, useState} from 'react';
import {
  Table,
  DatePicker,
  DeleteModal,
  Dropdown,
  DropdownItem,
  Button,
  TitlePortal,
  Portal
} from '~/components/ui';
import {useRepository} from '~/components/hooks';
import {useBreadcrumbs} from '~/context/breadcrumb'
import {Income, Pagination} from "~/api/models";

const PaysheetsScreen = () => {

  const { setBreadcrumb } = useBreadcrumbs()
  const [query, setQuery] = useState({ limit: 10, page: 1, with: 'account.bank', startDate: null, endDate: null })
  const [modals, setModals] = useState({ create: false, remove: false, assign: false })
  const [selected, setSelected] = useState<Income | undefined>(undefined)
  const [pagination, setPagination] = useState<Pagination<Income>>({ data: [] })
  const [loadings, setLoading] = useState({ index: false, remove: false })
  const { fetchIncomes, removePayment } = useRepository(({ incomes, reports }) => ({
    fetchIncomes: incomes.index,
    removePayment: incomes.remove,
    incomeReportURL: reports.income,
    incomesReportsURL: reports.incomes,
  }))

  useEffect(() => {
    fetch()
    setBreadcrumb([
      { title: 'Listado de ingresos', to: '/incomes', loading: false },
    ])

    return () => setBreadcrumb([])
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const select = (item: Income, cb: () => void) => {
    setSelected(item)
    cb()
  }

  const fetch = (filters = {}) => {

    setLoading(prev => ({...prev, index: !prev.index}))
    const _query = {...query, ...filters}

    fetchIncomes<Pagination<Income>>(_query)
      .then(setPagination)
      .then(() => setQuery(_query))
      .finally(() => setLoading(prev => ({...prev, index: !prev.index})))
  }

  const handleRemove = () => {
    setLoading(prev => ({...prev, remove: !prev.remove}))
    removePayment(selected!.id)
      .then(() => {
        fetch({ page: 1 })
        setModals(prev => ({...prev, remove: false}))
      })
      .finally(() => setLoading(prev => ({...prev, remove: !prev.remove})))
  }

  return (
    <>
      <TitlePortal>
        Listado de ingresos
      </TitlePortal>
      <Portal target="#main_action">
        <Button variant="primary" to="/incomes/create">
          Registrar ingreso
        </Button>
      </Portal>
      <div className="w-full p-2">
        <Table
          columnFilter
          onSearch={search => fetch({ search, page: 1 })}
          loading={loadings.index}
          source={pagination.data}
          pagination={pagination}
          onPageChange={page => fetch({ page })}
          onRefresh={() => fetch({ page: 1 })}
          columns={[
            {title: 'Fecha de creación', path: 'issued_at', filterField: () => (
                <DatePicker range onChange={([startDate, endDate]) => fetch({ startDate: startDate && `issued_at,${startDate}`, endDate: endDate && `issued_at,${endDate}` })} />
            )},
            {title: 'Para', path: 'account.bank.name'},
            {title: 'Desde', path: 'provider'},
            {title: 'Número', path: 'number'},
            {title: 'Monto', path: 'amount'},
            {title: 'Acciones', path: 'id', td: ({ item }) => (
              <Dropdown right>
                <DropdownItem href={`/incomes/${item.id}/edit`}>
                  Editar
                </DropdownItem>
                <DropdownItem onClick={() => select(item, () => setModals(prev => ({...prev, remove: true})))}>
                  Eliminar
                </DropdownItem>
              </Dropdown>
            ), columnFilterable: false },
        ]} />
      </div>

      <DeleteModal
        show={modals.remove}
        title="Eliminar órden de nómina"
        heading="Un momento!! ¿está seguro de que desea cotinuar?"
        subheading={
          <span>está por eliminar un ingreso</span>
        }
        loading={loadings.remove}
        onConfirm={handleRemove}
        onCancel={() => setModals(prev => ({...prev, remove: !prev.remove}))} />
    </>
  )
}

export default PaysheetsScreen
